<!-- Start Header -->
<header 
    *ngIf="!mainService.isDemoPage" 
    class="header js-header-scroll" 
    [class.header__white]="!mainService.isInnerPage && mainService.isHeaderWhite"
    [class.header__inner]="mainService.isInnerPage">

  
            <!--<select [ngModel]="langSelectedValue" (ngModelChange)="onSelectionChanged()">-->
               
        
     <!-- <div class="col-md-12">
               <label *ngIf="langVariable == 'SQ'" >Gjuha e përzgjedhur:</label>
            <label *ngIf="langVariable == 'EN'">Selected language:</label>
            <label *ngIf="langVariable == 'SR'">Odaberen jezik:</label>
            <select name="selectedLanguage" id="selectedLanguage" style="font-size: 10px; border-color: transparent; font-size:small; background-color: transparent;" (change)="onSelectionChanged($any($event.target).value)">
                <option value="null" style="color:blue">{{langVariable}}</option>
                <option value="SQ">SQ</option>
                <option value="SR">SR</option>
                <option value="EN">EN</option>
                
            </select>

 
    </div> -->

    <nav hidden >
        
        <div class="nav-header" style="height: 30px;">
            <a href="javascript:;" routerLink="/" class="brand">
                <img [src]="baseUrl + mainService.siteHeaderLogo" class="logo" [attr.alt]="mainService.siteName" />
            </a>
            <button class="toggle-bar">
                <span class="fa fa-bars"></span>
            </button>
        </div>		
        <!-- Start Header menu for mobile -->
        <div class="header__mobile js-header-menu">
            <a href="javascript:;" class="header__mobile-brand">Menu</a>
            <button class="toggle-bar header__mobile-toggle">
                <span class="fa fa-remove"></span>
            </button>
        </div>



        
        <!-- End Header menu for mobile -->	
        
<!--<div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-secondary active">
                  <input type="radio" name="options" id="option1" autocomplete="off" checked> Active
                </label>
                <label class="btn btn-secondary">
                  <input type="radio" name="options" id="option2" autocomplete="off"> Radio
                </label>
                <label class="btn btn-secondary">
                  <input type="radio" name="options" id="option3" autocomplete="off"> Radio
                </label>
              </div>-->
          
              
        <ul class="menu">
            <!--<DIV><button type="button" class="btn btn-primary  btn-EXsm">Primary</button></DIV>-->
            <div class="topmenugg">

                <label *ngIf="langVariable == 'SQ'" >Gjuha e përzgjedhur:</label>
                <label *ngIf="langVariable == 'EN'">Selected language:</label>
                <label *ngIf="langVariable == 'SR'">Odaberen jezik:</label>
                <select name="selectedLanguage" id="selectedLanguage" style="font-size: 12px; border-color: transparent; background-color: transparent;" (change)="onSelectionChanged($any($event.target).value)">
                    <option value="null" style="background-color: rgb(173, 177, 177);">{{langVariable}}</option>
                    <option value="SQ">SQ</option>
                    <option value="SR">SR</option>
                    <option value="EN">EN</option>
                <!--<option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>-->
                </select>
            </div>  
            <!--<select [ngModel]="langSelectedValue" (ngModelChange)="onSelectionChanged()">-->
            <!-- <select name="selectedLanguage" id="selectedLanguage" style="font-size: 12px; border-color: transparent; background-color: transparent;" (change)="onSelectionChanged($any($event.target).value)">
                <option value="null" style="background-color: rgb(173, 177, 177);">{{langVariable}}</option>
                <option value="SQ">SQ</option>
                <option value="SR">SR</option>
                <option value="EN">EN</option>
            
            </select> -->
            
            <!--<li><a href="javascript:;" routerLink="/" routerLinkActive="active">Fillimi</a></li>-->
            
            <!--<li><a href="javascript:;" routerLink="/about" routerLinkActive="active">Për ne</a></li>-->

            
            <li class="megamenu"><a href="javascript:;">
                
                <label *ngIf="langVariable == 'SQ'">Për ne</label>
                <label *ngIf="langVariable == 'EN'">About us</label>
                <label *ngIf="langVariable == 'SR'">Za nas</label>
            
            </a>
                <div class="megamenu-content megamenu-product">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-megamenu">
                                <div class="megamenu__widget">
                                    <!--<h6 class="megamenu__widget-title">Komisioni Rregullativ për Prokurim Publik</h6>-->
                                    <div class="megamenu__widget-group">
                                        <a href="/about?language={{langVariable}}" class="megamenu__widget-group-link">
                                            KRPP
                                            <!--<span>Informata rreth KRPP</span>-->
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="/team?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                            <label *ngIf="langVariable == 'SQ'">Bordi i KRPP</label>
                                            <label *ngIf="langVariable == 'EN'">KRPP Bord</label>
                                            <label *ngIf="langVariable == 'SR'">KRPP Bord</label>
                                            <!--<span>Bordi ynë</span>-->
                                        </a>
                                    </div>
<!--
                                    <div class="megamenu__widget-group">
                                        <a href="/about" class="megamenu__widget-group-link">
                                            Struktura organizative e KRPP
                                            <span>Rreth struktures organizative të KRPP</span>
                                        </a>
                                    </div>
-->
<!--
                                </div>
                            </div>
                            <div class="col-lg-6 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title"></h6>-->
                                    <div class="row">
                                        

                                    <h5 style="margin-bottom:0px">
                                        
                                        <label *ngIf="langVariable == 'SQ'">Dokumentet strategjike</label>
                                        <label *ngIf="langVariable == 'EN'">Strategic documents</label>
                                        <label *ngIf="langVariable == 'SR'">Strateški dokumenti</label>
                                    </h5> 
                                    <div class="megamenu__widget-group offset-md-1"style="margin-bottom:5px">
                                        
                                        
                                        <a href="/strategjia?language={{langVariable}}" class="megamenu__widget-group-link" style="margin-bottom:5px">
                                            <h6 style="margin-bottom:0px">
                                            
                                                <label *ngIf="langVariable == 'SQ'">Strategjia</label>
                                                <label *ngIf="langVariable == 'EN'">Strategy</label>
                                                <label *ngIf="langVariable == 'SR'">Strategija</label>
                                            
                                            </h6>
                                            <!--<span>Lista e dokumenteve strategjike</span>-->
                                        </a>
                                    </div>
                                    <div class="megamenu__widget-group offset-md-1" >
                                        <a href="/planperformance?language={{langVariable}}" class="megamenu__widget-group-link" style="margin-bottom:5px">
                                                                                        
                                            <label *ngIf="langVariable == 'SQ'">Planet e performancës</label>
                                            <label *ngIf="langVariable == 'EN'">Performance plans</label>
                                            <label *ngIf="langVariable == 'SR'">Planovi performansi</label>
                                            <!--<span>Lista e dokumenteve strategjike</span>-->
                                        </a>
                                    </div>
                                    <!--<div class="megamenu__widget-group offset-md-1">
                                        <a href="/raportetvjetore" class="megamenu__widget-group-link">
                                            Raportet vjetore
                                            
                                        </a>
                                    </div>-->
                                    <div class="megamenu__widget-group">
                                        <a href="/rregulloretkrpp?language={{langVariable}}" class="megamenu__widget-group-link">
                                                                                        
                                            <label *ngIf="langVariable == 'SQ'">Rregulloret e KRPP-se</label>
                                            <label *ngIf="langVariable == 'EN'">KRPP regulations</label>
                                            <label *ngIf="langVariable == 'SR'">KRPP propisi</label>
                                            <!--<span>Lista e rregulloreve te KRPP-se</span>-->
                                        </a>
                                    </div>
                                    

                                    <div class="megamenu__widget-group">
                                        <a href="/shqyrtimianulimit?language={{langVariable}}" class="megamenu__widget-group-link">
                                                                                        
                                            <label *ngIf="langVariable == 'SQ'">Shqyrtimi i anulimit te certifikave</label>
                                            <label *ngIf="langVariable == 'EN'">Assess of cancellation of certificates</label>
                                            <label *ngIf="langVariable == 'SR'">Procenjivanje poništenja sertifikata</label>
                                            <!--Vendimet e Bordit-->
                                            <!--<span>Shqyrtimi i anulimit te certifikave</span>-->
                                        </a>
                                    </div>
                                    

                                    <div class="megamenu__widget-group">
                                        <a href="/bashkpunimet?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                                                                    
                                            <label *ngIf="langVariable == 'SQ'">Bashkëpunimet</label>
                                            <label *ngIf="langVariable == 'EN'">Cooperations</label>
                                            <label *ngIf="langVariable == 'SR'">Saradnje</label>
                                            <!--<span>Bashkëpunimet</span>-->
                                        </a>
                                    </div>

                                    <!--<div class="megamenu__widget-group">
                                        <a href="https://e-prokurimi.rks-gov.net" class="megamenu__widget-group-link">
                                            Raportet e performances
                                            <span>Lista e raporteve te performances</span>
                                        </a>
                                    </div>-->



                                    </div>  
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </li>
            <li >
                <a href="javascript:;" href="https://e-prokurimi.rks-gov.net" routerLinkActive="active" style="color: blue; text-decoration: underline;" *ngIf="langVariable == 'SQ'">
                    e-Prokurimi
                </a>
                <a href="javascript:;" href="https://e-prokurimi.rks-gov.net" routerLinkActive="active" style="color: blue; text-decoration: underline;" *ngIf="langVariable == 'EN'">
                    e-Procurement
                </a>
                <a href="javascript:;" href="https://e-prokurimi.rks-gov.net" routerLinkActive="active" style="color: blue; text-decoration: underline;" *ngIf="langVariable == 'SR'">
                    e-Nabavka
                </a>
            </li>
            
            <li class="megamenu"><a href="javascript:;">

                                                                                    
                <label *ngIf="langVariable == 'SQ'">Legjislacioni</label>
                <label *ngIf="langVariable == 'EN'">Legislation</label>
                <label *ngIf="langVariable == 'SR'">Zakonodavstvo</label>

            </a>
                <div class="megamenu-content megamenu-product">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-megamenu">
                                <div class="megamenu__widget">
                                    <!--<h6 class="megamenu__widget-title">Ligjet</h6>-->
                                    <!-- <div class="megamenu__widget-group">
                                        <a href="/services?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                                    
                                        <label *ngIf="langVariable == 'SQ'">Ligjet</label>
                                        <label *ngIf="langVariable == 'EN'">Laws</label>
                                        <label *ngIf="langVariable == 'SR'">Zakoni</label>

                                        </a>
                                    </div> -->
                                    <div class="megamenu__widget-group">
                                        <a href="/legjislacioni?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                                    
                                        <label *ngIf="langVariable == 'SQ'">Ligjet</label>
                                        <label *ngIf="langVariable == 'EN'">Laws</label>
                                        <label *ngIf="langVariable == 'SR'">Zakoni</label>

                                            <!--<span>Informata rreth KRPP</span>-->
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="/legjsekondar?language={{langVariable}}" class="megamenu__widget-group-link">
                                           
                                            <label *ngIf="langVariable == 'SQ'">Legjislacioni sekondar</label>
                                            <label *ngIf="langVariable == 'EN'">Secondary legislation</label>
                                            <label *ngIf="langVariable == 'SR'">Podzakonski akti</label>
                                            <!--<span>Legjislacioni sekondar</span>-->
                                        </a>
                                    </div>
                                    <!--
                                    <div class="megamenu__widget-group">
                                        <a href="/gallery" class="megamenu__widget-group-link">
                                            Ligjet tjera
                                            <span>Ligjet tjera</span>
                                        </a>
                                    </div>-->


                                
                                    <!--<h6 class="megamenu__widget-title"></h6>-->
                                    <div class="megamenu__widget-group">
                                        <a href="/interpretimet?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                            <label *ngIf="langVariable == 'SQ'">Interpretimet</label>
                                            <label *ngIf="langVariable == 'EN'">Interpretations</label>
                                            <label *ngIf="langVariable == 'SR'">Tumačenje</label>
                                            <!--<span>Informata intepretimeve</span>-->
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="/faq?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                            <label *ngIf="langVariable == 'SQ'">Opinionet</label>
                                            <label *ngIf="langVariable == 'EN'">Opinions</label>
                                            <label *ngIf="langVariable == 'SR'">Mišljenje</label>
                                            <!--<span>Lista e opinioneve</span>-->
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="/direktivatbe?language={{langVariable}}" class="megamenu__widget-group-link">
                                           
                                            <label *ngIf="langVariable == 'SQ'">Direktivat e BE-se</label>
                                            <label *ngIf="langVariable == 'EN'">EU Directives</label>
                                            <label *ngIf="langVariable == 'SR'">Direktive EU</label>
                                            <!--<span>Direktivat e BE-se</span>-->
                                        </a>
                                    </div>
                                    <!--<div class="megamenu__widget-group">
                                        <a href="/gallery" class="megamenu__widget-group-link">
                                            Udhezime Administrative
                                            <span>Udhezime Administrative</span>
                                        </a>
                                    </div>-->

                                </div>

                            </div>
                            
                        </div>  
                    </div>
                </div>
            </li>

            
            <li><a href="javascript:;" href="/njoftimet?language={{langVariable}}" routerLinkActive="active">


                <label *ngIf="langVariable == 'SQ'">Njoftimet</label>
                <label *ngIf="langVariable == 'EN'">Notifications</label>
                <label *ngIf="langVariable == 'SR'">Obaveštenje</label>

            </a></li>

            <!--<li class="megamenu"><a href="javascript:;">Departamentet</a>
                <div class="megamenu-content megamenu-product">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title">Departamenti i rregullave</h6>
                                    <div class="megamenu__widget-group">
                                        <a href="/gallery" class="megamenu__widget-group-link">
                                            Interpretimet
                                            <span>Interpretimet</span>
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="https://ocdskrpp.rks-gov.net/Help" class="megamenu__widget-group-link">
                                            Opinionet
                                            <span>Opinionet</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title">Departamentit monitorimit</h6>
                                    <div class="megamenu__widget-group">
                                        <a href="https://e-prokurimi.rks-gov.net" class="megamenu__widget-group-link">
                                            Raportet
                                            <span>Raportet e monitorimit</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title">Departameti i integrimeve dhe trajnimeve </h6>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/about" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Modulet
                                                    <span>Modulet</span>
                                                </a>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/services" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Lista e trajnerëve
                                                    <span>Lista e trajnerëve</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/faq" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Lista e Zyrtareve te certifikuar
                                                    <span>Lista e Zyrtareve te certifikuar</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/team" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Video manualet
                                                    <span>Video manualet</span>
                                                </a>
                                            </div>
                                        </div>
                                        
                                    </div>  
                                </div>
                            </div>


                        </div>  
                    </div>
                </div>
            </li>-->

            <!--<li><a href="javascript:;" routerLink="/legjislacioni" routerLinkActive="active">Legjislacioni</a></li>-->
            
            <!--<li><a href="javascript:;" routerLink="/services" routerLinkActive="active">Legjislacioni</a></li>-->
            <!--<li><a href="javascript:;" routerLink="/pricing" routerLinkActive="active">Manualet</a></li>
            <li class="megamenu"><a href="javascript:;">Informacione</a>
                <div class="megamenu-content megamenu-product">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title">Shërbimet e ofruara nga KRPP</h6>
                                    <div class="megamenu__widget-group">
                                        <a href="https://e-prokurimi.rks-gov.net" class="megamenu__widget-group-link">
                                            e-Prokurimi
                                            <span>Platforma online e-prokurimi</span>
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="https://ocdskrpp.rks-gov.net/Help" class="megamenu__widget-group-link">
                                            OCDS
                                            <span>Webshërbimi sipas standardit ndërkombëtar OCDS</span>
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="https://e-prokurimi.rks-gov.net" class="megamenu__widget-group-link">
                                            Trajnime
                                            <span>Trajnimet e ofruara nga KRPP</span>
                                        </a>
                                    </div>

                                    
                                </div>
                            </div>
                            <div class="col-lg-8 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title">Uebfqaja e KRPP</h6>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/about" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Rreth nesh
                                                    <span>Më shumë detaje rreth KRPP'së</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/gallery" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Galeria
                                                    <span>Foto dhe imazhe</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/services" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Shërbimet dhe veprimtaritë
                                                    <span>Të listuara do të mundë ti gjeni shërbimet dhe veprimtaritë e KRPP</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/faq" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Pyetjet e shpeshta
                                                    <span>Lista e pyetjeve të shpeshta</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/team" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Stafi ynë
                                                    <span>Njihuni me stafin tonë</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/career" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Vende pune
                                                    <span>Bashkohuni ekpit tonë</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/blog" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Blogu
                                                    <span>Lajmet, njoftimet, etj.</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/testimonial" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Motoja e jonë
                                                    <span>Qfar thonë të tjerët për ne</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </li>
            <li><a href="javascript:;" routerLink="/career" routerLinkActive="active">Manualet</a></li>-->


            <li class="megamenu"><a href="javascript:;">

                

                <label *ngIf="langVariable == 'SQ'">Trajnimet</label>
                <label *ngIf="langVariable == 'EN'">Trainings</label>
                <label *ngIf="langVariable == 'SR'">Trening</label>
            </a>
                <div class="megamenu-content megamenu-product">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-megamenu">
                                <div class="megamenu__widget">
                                    <h2 class="megamenu__widget-title" style="color: blue;">
                                    
                                        <label *ngIf="langVariable == 'SQ'">Manualet</label>
                                        <label *ngIf="langVariable == 'EN'">Manuals</label>
                                        <label *ngIf="langVariable == 'SR'">Priručnici</label>
                                    </h2>
                                                        
                                    
                                    <div class="megamenu__widget-group">
                                        <a href="/manualet?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                            
                                        <label *ngIf="langVariable == 'SQ'">Manualet</label>
                                        <label *ngIf="langVariable == 'EN'">Manuals</label>
                                        <label *ngIf="langVariable == 'SR'">Priručnici</label>
                                            <!--<span>Platforma online e-prokurimi</span>-->
                                        </a>
                                        
                                    </div>

                                    <!-- <div class="megamenu__widget-group">
                                        <a href="/videomanualeto?language={{langVariable}}" class="megamenu__widget-group-link">
                                                              
                                            <label *ngIf="langVariable == 'SQ'">Video manualet</label>
                                            <label *ngIf="langVariable == 'EN'">Video manuals</label>
                                            <label *ngIf="langVariable == 'SR'">Video priručnici</label>
                                            
                                        </a>
                                    </div> -->



                                    <div class="megamenu__widget-group">
                                        <a href="/videomanualeta?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                            <label *ngIf="langVariable == 'SQ'">Video manualet</label>
                                            <label *ngIf="langVariable == 'EN'">Video manuals</label>
                                            <label *ngIf="langVariable == 'SR'">Video priručnici</label>
                                            <!--<span>Platforma online e-prokurimi</span>-->
                                        </a>
                                        
                                    </div>
                                    <!-- <h5 style="margin-bottom:0px">
                                        
                                        <label *ngIf="langVariable == 'SQ'">Video manualet</label>
                                        <label *ngIf="langVariable == 'EN'">Video manuals</label>
                                        <label *ngIf="langVariable == 'SR'">Video priručnici</label>
                                    </h5> 
                                    <div class="megamenu__widget-group offset-md-1"style="margin-bottom:5px">
                                        
                                        
                                        <a href="/videomanualeta?language={{langVariable}}" class="megamenu__widget-group-link" style="margin-bottom:5px">
                                            <h6 style="margin-bottom:0px">
                                            
                                                <label *ngIf="langVariable == 'SQ'">Operatoret Ekonomik</label>
                                                <label *ngIf="langVariable == 'EN'">Economic Operators</label>
                                                <label *ngIf="langVariable == 'SR'">Ekonomske operatere</label>
                                            
                                            </h6>
                                            
                                        </a>
                                    </div>
                                    <div class="megamenu__widget-group offset-md-1" >
                                        <a href="/videomanualeta?language={{langVariable}}" class="megamenu__widget-group-link" style="margin-bottom:5px">
                                            <h6 style="margin-bottom:0px">

                                                <label *ngIf="langVariable == 'SQ'">Autoritetet Kontraktuese</label>
                                                <label *ngIf="langVariable == 'EN'">Contracting Autorites</label>
                                                <label *ngIf="langVariable == 'SR'">Ugovorne autoritete</label>
                                                
                                            </h6>
                                            
                                        </a>
                                    </div> -->





                                    <div class="megamenu__widget-group">
                                        <a href="/menaxhimikontratesv?language={{langVariable}}" class="megamenu__widget-group-link">
                                            
                                            <label *ngIf="langVariable == 'SQ'">Menaxhimi i kontrates</label>
                                            <label *ngIf="langVariable == 'EN'">Contract management</label>
                                            <label *ngIf="langVariable == 'SR'">Upravljanje ugovorima</label>
                                            <!--<span>Platforma online e-prokurimi</span>-->
                                        </a>
                                        
                                    </div>

                                    <!-- <h5 style="margin-bottom:0px">
                                        
                                        <label *ngIf="langVariable == 'SQ'">Menaxhimi i kontrates</label>
                                        <label *ngIf="langVariable == 'EN'">Contract management</label>
                                        <label *ngIf="langVariable == 'SR'">Upravljanje ugovorima</label>
                                    </h5> 
                                    <div class="megamenu__widget-group offset-md-1"style="margin-bottom:5px">
                                        <a href="/videomanualeto?language={{langVariable}}" class="megamenu__widget-group-link" style="margin-bottom:5px">
                                            <h6 style="margin-bottom:0px">
                                            
                                                <label *ngIf="langVariable == 'SQ'">Operatoret Ekonomik</label>
                                                <label *ngIf="langVariable == 'EN'">Economic Operators</label>
                                                <label *ngIf="langVariable == 'SR'">Ekonomske operatere</label>
                                            
                                            </h6>
                                            
                                        </a>
                                    </div>
                                    <div class="megamenu__widget-group offset-md-1" >
                                        <a href="/menaxhimikontratesv?language={{langVariable}}" class="megamenu__widget-group-link" style="margin-bottom:5px">
                                            <h6 style="margin-bottom:0px">

                                                <label *ngIf="langVariable == 'SQ'">Manualet video</label>
                                                <label *ngIf="langVariable == 'EN'">Video Manuals</label>
                                                <label *ngIf="langVariable == 'SR'">Video priručnici</label>
                                                
                                            </h6>
                                            
                                        </a>
                                    </div> -->

                                    <div class="megamenu__widget-group">
                                        <a href="https://e-prokurimi.rks-gov.net" class="megamenu__widget-group-link">
                                                                                        
                                            <label *ngIf="langVariable == 'SQ'">Vlerësimi i Perfomances</label>
                                            <label *ngIf="langVariable == 'EN'">Performance Evaluation</label>
                                            <label *ngIf="langVariable == 'SR'">Procena učinka</label>
                                            <!--<span>Vlerësimi i Perfomances</span>-->
                                        </a>
                                    </div>

                                    <div class="megamenu__widget-group">
                                        <a href="https://e-prokurimi.rks-gov.net" class="megamenu__widget-group-link">
                                                                                  
                                            <label *ngIf="langVariable == 'SQ'">Manuali për Auditim</label>
                                            <label *ngIf="langVariable == 'EN'">Audit Manual</label>
                                            <label *ngIf="langVariable == 'SR'">Priručnik za reviziju</label>
                                            <!--<span>Manuali për Auditim</span>-->
                                        </a>
                                    </div>

                                    
                                </div>
                            </div>
                            <div class="col-lg-6 col-megamenu">
                                <div class="megamenu__widget">
                                    <h6 class="megamenu__widget-title" style="color: blue;">
                                                                    
                                            <label *ngIf="langVariable == 'SQ'">Trajnimet</label>
                                            <label *ngIf="langVariable == 'EN'">Trainings</label>
                                            <label *ngIf="langVariable == 'SR'">Trening</label>
                                    </h6>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a href="/about?language={{langVariable}}" class="megamenu__widget-group-link">
                                                                                                                             
                                                    <label *ngIf="langVariable == 'SQ'">Modulet</label>
                                                    <label *ngIf="langVariable == 'EN'">Moduls</label>
                                                    <label *ngIf="langVariable == 'SR'">Moduli</label>
                                                    <!--<span>Lista e materialeve/modulet</span>-->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a href="/listaetrajnereve?language={{langVariable}}" class="megamenu__widget-group-link">
                                                                                                                             
                                                    <label *ngIf="langVariable == 'SQ'">Lista e trajnerëve për prokurim</label>
                                                    <label *ngIf="langVariable == 'EN'">List of procurement trainers</label>
                                                    <label *ngIf="langVariable == 'SR'">Spisak instruktora nabavke</label>
                                                    <!--<span>Lista e trajnerëve për prokurim</span>-->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a href="/listaezyrtareve?language={{langVariable}}" class="megamenu__widget-group-link">
                                                                                                        
                                                    <label *ngIf="langVariable == 'SQ'">Lista e zyrtarëve të pajisur me çertifikatë të Avancuar profesionale për Prokurim</label>
                                                    <label *ngIf="langVariable == 'EN'">List of officials equipped with Advanced Professional Certificate for Procurement</label>
                                                    <label *ngIf="langVariable == 'SR'">Spisak službenika opremljenih naprednim profesionalnim sertifikatom za nabavku</label>
                                                    <!--<span>Lista e zyrtarëve të pajisur me çertifikatë të Avancuar profesionale për Prokurim</span>-->
                                                </a>
                                            </div>
                                        </div>
                                        <!--<div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a routerLink="/faq" routerLinkActive="active" class="megamenu__widget-group-link">
                                                    Shqyrtimi i anulimit të çertifikatave për prokurim nga KRPP
                                                    <span>Shqyrtimi i anulimit të çertifikatave për prokurim nga KRPP</span>
                                                </a>
                                            </div>
                                        </div>-->
                                        
                                    </div>  
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </li>
            <!--trajnimet dhe manualet-->
            <!--<li><a href="javascript:;" routerLink="/blog" routerLinkActive="active">Lajmet</a></li>-->
            
            <!--<li><a href="javascript:;" routerLink="/contact" routerLinkActive="active">Raportet</a></li>-->

            
            <li class="megamenu"><a href="javascript:;">
                                     
                <label *ngIf="langVariable == 'SQ'">Raportet</label>
                <label *ngIf="langVariable == 'EN'">Reports</label>
                <label *ngIf="langVariable == 'SR'">Izveštaji</label>
            </a>
                <div class="megamenu-content megamenu-product">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-megamenu">
                                <div class="megamenu__widget">
                                    
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a href="/raportetperformance?language={{langVariable}}" class="megamenu__widget-group-link">
                                                    
                                                    
                                                    <label *ngIf="langVariable == 'SQ'">Raportet e performancës</label>
                                                    <label *ngIf="langVariable == 'EN'">Performance reports</label>
                                                    <label *ngIf="langVariable == 'SR'">Izveštaji o učinku</label>
                                                    <!--<span>Lista e materialeve/modulet</span>-->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a href="/raportetvjetore?language={{langVariable}}" class="megamenu__widget-group-link">
                                                    
                                                    
                                                    <label *ngIf="langVariable == 'SQ'">Raportet vjetore</label>
                                                    <label *ngIf="langVariable == 'EN'">Anual reports</label>
                                                    <label *ngIf="langVariable == 'SR'">Godišnji izvještaji</label>
                                                    <!--<span>Lista e trajnerëve për prokurim</span>-->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="megamenu__widget-group">
                                                <a href="/raportetstatistikore?language={{langVariable}}" class="megamenu__widget-group-link">
                                                    
                                                    
                                                    <label *ngIf="langVariable == 'SQ'">Raportet statistikore</label>
                                                    <label *ngIf="langVariable == 'EN'">Statistical reports</label>
                                                    <label *ngIf="langVariable == 'SR'">Statistički izveštaji</label>
                                                    <!--<span>Lista e zyrtarëve të pajisur me çertifikatë të Avancuar profesionale për Prokurim</span>-->
                                                </a>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </li>



            <li><a href="javascript:;" href="/contact?language={{langVariable}}" routerLinkActive="active">
                
                <label *ngIf="langVariable == 'SQ'">Kontakti</label>
                <label *ngIf="langVariable == 'EN'">Contacts</label>
                <label *ngIf="langVariable == 'SR'">Kontakti</label>

            </a></li>

            <li>
                
                <a href="https://www.facebook.com/profile.php?id=100089577137622" class="" style="color: #0a348f; text-decoration: underline;" >
                
                
                 
                <label *ngIf="langVariable == 'SQ'">Na ndiqni në:</label>
                <label *ngIf="langVariable == 'EN'">Follow us:</label>
                <label *ngIf="langVariable == 'SR'">Pratite nas:</label> 
               
                
                
                <i class="fa-brands fa-facebook-f" style="background:#0a348f;display:inline-block;border-radius:48%;text-align:center;color:#ffffff;font-size:12px;overflow:hidden;margin-left:10px;margin-right:0px; width:15px;height:15px; padding-top:2px"></i></a></li>
                        <!--<li><a href="javascript:;" class="footer__widget-network-link"><i class="fa-brands fa-twitter"></i></a></li>
                        <li><a href="javascript:;" class="footer__widget-network-link"><i class="fa-brands fa-linkedin"></i></a></li>-->
            <!--<li><a href="https://www.youtube.com/channel/UCH5B28pIpHpsm7XVL0O0Mag" class=""><i class="fa-brands fa-youtube"></i></a></li>border-radius:50%-->
        </ul>
        <!--<ul class="attributes">
            <li class="header__button"><a href="https://e-prokurimi.rks-gov.net" class="btn btn-primary btn-rounded btn-xs btn-header">e-prokurimi</a></li>#0a348f
            <li class="header__download-icon"><a href="https://e-prokurimi.rks-gov.net"><i class="fa fa-download"></i></a></li>
        </ul>-->
    </nav>
</header>
<!-- End Header -->

<!-- Start Demo Header -->
<div *ngIf="mainService.isDemoPage" class="demo__header">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <a href="#" class="demo__header--logo"><img src="assets/brand/logo-white.png" class="logo" alt="Smooth" /></a>
            </div>
            <div class="col-6">
                <a href="https://themeforest.net/item/smooth-software-company-landing-page/25485298" class="btn btn-green btn-rounded btn-xs btn-header demo__header--action">Download</a>
            </div>
        </div>
    </div>
</div>
<!-- End Demo Header -->