<!-- Start Time -->
<div class="team__card js-match-height {{className}}">
    <div class="team__thumbnail"[ngStyle]="{ display: 'flex', 'align-items': 'flex-start' }">
        <img [src]="avatar" class="img-fluid" alt="" [ngStyle]="{ 'margin-right': '20px' }" />
        <div [ngStyle]="{ 'align-self': 'flex-start' }">
            
            <h4 class="team__name">{{name}}</h4>
            <br />

            <span class="team__position"><strong>Pozita: </strong> {{pozita}}</span>
            <br />
            

            <span class="team__position"><strong>Vendlindja: </strong> {{vendelindja}}</span>
            <br />
            
            <!--<span class="team__position"><strong>Ditelindja: </strong> {{datelindja}}</span>-->
            <br />



        </div>
      </div>
    <div class="team__content">
        <!-- <div class="team__content--heading">
            <h4 class="team__name">{{name}}</h4>
            <span class="team__position">{{title}}</span>
            
        </div><span>{{about}}</span> -->

        <!-- <span>{{about}}</span> -->
        <div class="team__about" [innerHTML]="about"></div>

        <ul class="team__network">
            <!--<li><a [href]="facebookLink" class="team__network--link" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
            <li><a [href]="twitterkLink" class="team__network--link" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
            <li><a [href]="linkEdinkLink" class="team__network--link" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>-->
        </ul>
    </div>
</div>
<!-- End Time -->